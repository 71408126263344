import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { exchange_credentials as exchangeCredentials } from 'app/django-adapter';
import RenderRootComponent from 'components/render-utils/render-root-component';
import { bindActionCreators } from 'redux';
import { Provider, connect } from 'react-redux';
import { components } from 'react-select';
import { store } from 'app/redux/store';
import ContentLoader from 'react-content-loader';
import * as ProfileActions from 'actions/profiles';
import * as Fields from 'components/segment/fields';
import { ProfilesReducerTypes, ProfilesActionsTypes } from 'app/types/profiles';
import ResourceStatusv2 from 'components/render-utils/resource-statusv2';
import SimpleToast from 'components/render-utils/simple-toast';
import Tooltip from 'components/help-tooltip';
import ErrorBoundary from 'components/error/error-boundary';

import './style.scss';

const { Option } = components;

export class App extends React.Component {
  static propTypes = {
    profiles: ProfilesReducerTypes,
    profileActions: PropTypes.shape(ProfilesActionsTypes).isRequired,
  }

  static defaultProps = {
    profiles: {
      selectedProfile: undefined,
      data: [],
      isLoading: true,
      isError: false,
      isInitialState: true,
    },
  }

  static MyOption = ({ className, ...otherProps }) => (
    <Option {...otherProps} className={otherProps.data.parentTechPrefix ? 'react-select__sub-option' : className} />
  );

  state = {
    message: '',
    isSwitching: false,
  };

  componentDidMount() {
    this.props.profileActions.getProfiles();
  }

  onCloseToast = () => {
    this.setState({ message: '' });
    this.props.profileActions.clearMessage();
  }

  getMessage() {
    const { profiles: { message: messageProps } } = this.props;
    const { message: messageState } = this.state;

    if (messageProps) {
      return messageProps;
    }
    if (messageState) {
      return messageState;
    }
    return '';
  }

  handleInput = async (event) => {
    try {
      this.setState({ isSwitching: true });
      await this.props.profileActions.setProfile(event.target.value);
      setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
      this.setState({ isSwitching: false });
      console.error(error);
    }
  }

  render() {
    const { profiles } = this.props;
    const { isSwitching } = this.state;

    if (!profiles.data.length && !profiles.isInitialState) {
      return null;
    }

    if (profiles.data.length === 1) { // don't show picker if only self in list
      return (
        <ErrorBoundary>
          <div className="project-bar-selector">
            <Tooltip
              id="subaccounts-help"
              tooltip="Subaccounts help organize your Flowroute numbers and configuration.<br />When you have created a subaccount, a selector will appear here that will allow you to switch between accounts easily!"
              place="bottom"
            >
              <a className="btn" label="Add a SubAccount" href="/accounts/advanced_settings/subaccounts/create">
                Add a SubAccount
              </a>
            </Tooltip>
          </div>
        </ErrorBoundary>
      );
    }

    const Placeholder = (
      <div className="profile-selector__loading">
        <ContentLoader width={300} height={50} backgroundColor="#1b273a" foregroundColor="#465f86" speed={1} animated="true">
          <rect x="100" y="0" rx="5" ry="5" width="200" height="50" />
          <rect x="0" y="17" rx="4" ry="4" width="90" height="18" />
        </ContentLoader>
      </div>
    );

    return (
      <ResourceStatusv2
        className={'profile-selector-wrapper'}
        response={profiles}
        placeholder={Placeholder}
      >
        <SimpleToast
          message={this.getMessage()}
          type={profiles.isError ? 'error' : 'info'}
          onClose={this.onCloseToast}
          timeout={3000}
        />
        <Fields.SelectSearch
          aria-label="Switch account"
          name="profile_selector"
          options={profiles.data}
          onChange={this.handleInput}
          className="profile-selector"
          optionComponent={App.MyOption}
          components={{
            Option: App.MyOption,
          }}
          disabled={isSwitching}
          clearable={false}
          placeholder="Switch account..."
        />
      </ResourceStatusv2>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    profileActions: bindActionCreators(ProfileActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    profiles: state.profileSelector,
    customer: state.customer
  };
}

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

/* istanbul ignore next */
const Root = () => (
  <Provider store={store}>
    <ConnectedApp />
  </Provider>
);

document.addEventListener("DOMContentLoaded", () => {
  RenderRootComponent(hot(module)(Root), "profile-selector");
});

export default ConnectedApp;