import PropTypes from 'prop-types';

export const ProfilesActionsTypes = {
  getProfiles: PropTypes.func.isRequired,
  setProfile: PropTypes.func.isRequired,
  clearMessage: PropTypes.func.isRequired,
}.isRequired;

export const ProfilesTypes = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
});

export const ProfilesListTypes = PropTypes.arrayOf(ProfilesTypes);

export const ProfilesReducerTypes = PropTypes.shape({
  data: ProfilesListTypes.isRequired,
  selectedProfile: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string,
  isError: PropTypes.bool.isRequired,
  isInitialState: PropTypes.bool.isRequired,
});
